












































































































import alertS from '@/services/AlertService/AlertService';
import rService from '@/services/RequestService/RequestServiceSetup';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Pricing',
  props: {},
  components: {},
  data() {
    return {
      showNotRecommended: false,
      readOnly: false,
      redeemAutomatic: false,
      paymentDialog: false,
      cardLoading: false,
      dialog: false,
      sub: {},
      plans: [],
      iframe: null,
      loadingIframe: false,
    };
  },
  methods: {
    getPeriod(plan) {
      const isLifetime = plan.durationDays == 0;
      if (isLifetime) return 'lifetime';
      return 'month';
    },
    muted(plan, type, fa) {
      function succ(fa) {
        if (fa) {
          return 'fa-check';
        } else {
          return '';
        }
      }
      let toReturn = 'text-muted';
      if (fa) toReturn = 'fa-times';
      const role = plan.role;
      switch (type) {
        case 'upcoming':
          if (role != 'free' && role !== 'unlock') toReturn = succ(fa);
          break;
        case 'download':
          if (plan.download_result == 1) toReturn = succ(fa);
          break;
        case 'tab_unlock':
          if (role != 'free') toReturn = succ(fa);
          break;
        default:
          const types = plan.types;
          const search = types.find((el) => {
            return el == 'all' || el.includes(type);
          });
          if (search != undefined) {
            toReturn = succ(fa);
          }
          break;
      }
      return toReturn;
    },
    async getPlans() {
      this.loadBarS(true);
      const result = await rService.get('plans');
      this.plans = result.map((el) => {
        el.types = el.types.split(',');
        return el;
      });
      this.loadBarS(false);
    },
    ...mapActions(['updatePlan', 'openLogin']),
    disableStatus(sub) {
      switch (sub) {
        case 'free':
          // return this.userInfo.plan && this.userInfo.plan != 'unverified';
          break;

        default:
          break;
      }
    },
    load() {
      this.loadingIframe = false;
    },
    text(item) {
      if (item == this.userInfo.plan) {
        return this.translate('currentPlan');
      } else if (item == 'free') {
        return this.translate('free');
      } else {
        return this.translate('pay');
      }
    },
    translate(item, plan = null) {
      if (plan === 'unlock' && item === 'tab_unlock') {
        item = 'tab_unlock_exp';
      }
      return this.$vuetify.lang.t('$vuetify.Pricing.' + item);
    },
    fixRole(sub) {
      if (sub === 'pro_lifetime') {
        return 'pro';
      }
      return sub;
    },
    openDialog(sub) {
      this.cardLoading = false;
      this.readOnly = false;
      //this.redeemAutomatic = true;
      this.showNotRecommended = false;
      if (!this.loggedIn) {
        this.openLogin();
        return alertS.error('You need to be logged in first');
      }
      if (sub.role == 'free' && this.userInfo.plan && this.userInfo.plan != 'unverified') {
        return alertS.error(this.translate('alreadyHave'));
      }
      this.sub = sub;
      if (sub.role !== 'free') {
        if (sub.role === 'unlock' && this.userInfo.unlock_role) {
          this.redeemAutomatic = false;
          this.readOnly = true;
        }
        if (this.userInfo.plan !== 'free' && this.userInfo.plan !== this.fixRole(sub.role)) {
          this.redeemAutomatic = false;
          this.showNotRecommended = true;
        }
      }
      this.dialog = true;
    },

    async getFreeSub() {
      this.dialog = false;
      const freeSub = this.translate('processingFree');
      alertS.loading(freeSub);
      const res = await rService.post('free_sub', {}, false, true);
      if (res.error) return alertS.error('Error getting sub');
      alertS.success('Thanks for trusting us! You can use our search service twice a day for free');
      this.updatePlan(res);
    },
    async getPaidSub(sub) {
      return window.open(this.getPaidSubLink(sub), '_blank');
    },
    getPaidSubLink(sub) {
      return 'https://store.shellix.xyz/products/' + sub.product_id;
    },
    getLink() {
      let res = '#';
      switch (this.sub.role) {
        case 'free':
          res = '#';
          break;
        default:
          res = this.getPaidSubLink(this.sub);
          break;
      }
      return res;
    },
    getSub() {
      if (this.sub && this.sub.role) {
        switch (this.sub.role) {
          case 'free':
            this.getFreeSub();
            break;
          default:
            this.getPaidSub(this.sub);
            break;
        }
      }
    },
  },
  mounted() {
    this.getPlans();
  },
  computed: {
    ...mapGetters(['userInfo', 'loggedIn']),
  },
};
